import React, { useContext } from "react";
import { AppContext } from "../../pages/game/Game";
import { useSelector, useDispatch } from 'react-redux';
import keySoundFile from '../../assets/click.mp3';
import { shiftAction } from "../../actions";
import "./key.css";


function Key({keyVal, bigKey, actionKey, keyColor}) {
  const {onSelectKey, onEnter, onBackspace} = useContext(AppContext);
  const gameoverState = useSelector(state => state.isGameover);
  const toggleKeyboard = useSelector(state => state.isShiftClicked);
  const dispatch = useDispatch();

  const keySound = new Audio(keySoundFile); // ✅ Load the imported sound

  const playKeyPressSound = () => {
      keySound.currentTime = 0; // Reset sound to allow rapid presses
      keySound.play();
  };
  
  
  
  const selectKey = () => {
    if(gameoverState) return;

    playKeyPressSound();

    if (actionKey == null){
      onSelectKey(keyVal);
      
    } else if (actionKey === "enter"){
      onEnter();
      
    } else if (actionKey === "backspace"){
      onBackspace();
    } else if (actionKey === "shift"){
      dispatch(shiftAction(!toggleKeyboard));
    }
  };

  



  return (
    <div className="key" id={bigKey ? "big" : keyColor  } onClick={selectKey}>
      {keyVal}
    </div>
  );
}

export default Key;



