import React from "react";
import "./coupon.css";

const Coupon = ({ code, date }) => {

  // Function to convert numbers to Bengali
const convertToBengaliNumbers = (num) => {
  const bengaliDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
  return num.toString().replace(/\d/g, (digit) => bengaliDigits[digit]);
};

// Function to format the date in Bengali
const formatDateToBengali = (dateString) => {
  const dateObj = new Date(dateString);

  // Bengali month names
  const bengaliMonths = [
      "জানুয়ারি", "ফেব্রুয়ারি", "মার্চ", "এপ্রিল", "মে", "জুন",
      "জুলাই", "আগস্ট", "সেপ্টেম্বর", "অক্টোবর", "নভেম্বর", "ডিসেম্বর"
  ];

  const day = convertToBengaliNumbers(dateObj.getDate());
  const month = bengaliMonths[dateObj.getMonth()];
  const year = convertToBengaliNumbers(dateObj.getFullYear());

  return `${day} ${month} ${year}`;
};


  return (
    < div className="coupon">
          <div className="coupon-container">
            <div className="coupon-info">
              <p className="coupon-code">{code}</p>
              <p className="coupon-date">{formatDateToBengali(date)}</p>
            </div>
          </div>
    </div>
  );
};

export default Coupon;