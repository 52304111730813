import React, {useState, createContext, useEffect} from 'react';
import { Wordgrid, Keyboard, History, Settings, Help, ShobdleChecker } from '../../containers';
import { Navbar, FloatingButton } from '../../components';
import { useDispatch, useSelector} from 'react-redux';
import { gameoverAction, helpAction, historyAction, historyDataAction, positiongridAction, shobdleCheckerAction, triedWordsAction, wordgridAction } from '../../actions';
import { wordgridDefault, positionGridDefault, diarects, hosonto, generateWordSet} from '../../helpers/Words';
import { Date } from 'core-js';
import { getShobdle, getUserInfo, saveCouponToFirestore, uploadPlayHistory } from '../../helpers/firebase';
import {useParams,  useNavigate } from 'react-router-dom';
import ReactLoading from "react-loading";
import { generateBengaliCouponCode } from '../../helpers/couponGenerator';
import winSoundFile from "../../assets/win.mp3";
import failSoundFile from "../../assets/fail.mp3";
import './game.css';

export const AppContext = createContext();


function Game () {
  const { userId } = useParams();
  const navigate = useNavigate();
  const [wordSet, setWordSet] = useState(new Set());
  const [currAttempt, setCurrAttempt] = useState({attemptVal : 0, alphabetPos : 0});
  const [message, setMessage] = useState("");
  const dispatch = useDispatch();
  const keyState = {};
  const isShobdleChecker = useSelector(state => state.isShobdleChecker);
  const isHistory = useSelector(state => state.isHistory);
  const isSettings = useSelector(state => state.isSettings);
  const isHelp = useSelector(state => state.isHelp);
  var triedWordsDefault = {words: ""};
  const today = new Date();
  //var date = today.getFullYear() + "/" + ("0" + (today.getMonth()+1)).slice(-2) +  "/" + ("0" + today.getDate()).slice(-2);
  var date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
  var historyObjectDefault = {played: "0", win: "0", winPer: "0", currStreak: "0", maxStreak: "0", currAttempt: {attemptVal : 0, alphabetPos : 0}, theme: "light", contrast: false, date: "", winDate: "", coupon: null};
  var WordGridDefault = wordgridDefault;
  var PositionGridDefault = positionGridDefault;
  const positiongrid = useSelector(state => state.isPositiongrid);
  var snackbar = document.getElementById("snackbar");
  var historyObject = useSelector(state => state.isHistoryData);
  var wordgrid = useSelector(state => state.isWordGrid);
  var triedWords = useSelector(state => state.isTriedWords);
  var shobdle = useSelector(state => state.isShobdle);
  const alphabetElement = document.getElementsByClassName('alphabet');
  const [userInfo, setUserInfo] = useState(new Set());
  const [loading, setLoading] = useState(false);
  var couponCode = "None";


  useEffect(() => {

    const fetchData = async () => {
      try {
        setWordSet(generateWordSet().wordSet);
        // Fetch user info
        const userData = await getUserInfo(userId);
  
        if (!userData) {
          // User document does not exist or is null
          navigate('/');
          return;
        }
  
        // User data is available
        setUserInfo(userData);
        localStorage.setItem(userId + '-creatorInfo', JSON.stringify(userData));
  
        // Get Today's Shobdle
        //const today2 = new Date();
        //const formattedDate = `${today2.getDate()}-${today2.getMonth() + 1}-${today2.getFullYear()}`;
        const shobdleSuccess = await getShobdle(userId, date, shobdle, dispatch);
  
        if (!shobdleSuccess) {
          // Shobdle document doesn't exist for the given date
          // Show shobdleChecker Modal
          dispatch(shobdleCheckerAction(true));
          return;
        }
  
      } catch (error) {
        console.error('Error during initialization:', error.message);
        // Handle the error, e.g., show an error message or take appropriate action
      }

      // Get History Data from local storage for the current Shobdle Provider
      if(localStorage.getItem(userId + '-history') != null){
        historyObjectDefault = JSON.parse(localStorage.getItem(userId + '-history'));
        historyObjectDefault.currStreak = (getDateDifference(date,historyObjectDefault.winDate ) <= 1) ? historyObjectDefault.currStreak : "0";
        historyObjectDefault.currAttempt = historyObjectDefault.date === date ? historyObjectDefault.currAttempt : {attemptVal : 0, alphabetPos : 0};
      } 

      if(localStorage.getItem(userId + '-triedWords') != null){
        triedWordsDefault = historyObjectDefault.date === date ? JSON.parse(localStorage.getItem(userId + '-triedWords')) : {words: ""};
      } 
      if(localStorage.getItem(userId +'-wordgrid') != null){
        WordGridDefault = historyObjectDefault.date === date ? JSON.parse(localStorage.getItem(userId +'-wordgrid')) : wordgridDefault;
      } 
      if(localStorage.getItem(userId +'-positiongrid') != null){
        PositionGridDefault = historyObjectDefault.date === date ? JSON.parse(localStorage.getItem(userId +'-positiongrid')) : positionGridDefault;
      } 
      


      //dispatch(shobdleAction(shobdle));
      dispatch(historyDataAction(historyObjectDefault));
      dispatch(wordgridAction(WordGridDefault));
      dispatch(positiongridAction(PositionGridDefault));
      dispatch(triedWordsAction(triedWordsDefault));
      setCurrAttempt({attemptVal: historyObjectDefault.currAttempt.attemptVal, alphabetPos: historyObjectDefault.currAttempt.alphabetPos});

      //dispatch(helpAction(true));
      setLoading(false);
      };

    // If playerName is not set then move to profile page
    if (!JSON.parse(localStorage.getItem(userId + '-playerName'))){
      navigate(`/profile/${userId}`);
    };

    // Show loading animation
    setLoading(true);

    // Call the async function immediately
    fetchData();
  }, []);


  const onSelectKey = (keyVal) => {
    if(currAttempt.alphabetPos > 3) return;
    

    if (historyObject.winDate === date) return;

    
    const newWordgrid = wordgrid;

    if(newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] === ""){
      if (diarects.includes(keyVal) || hosonto.includes(keyVal)){
        return;
      } else {
        newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] = keyVal;
        dispatch(wordgridAction(newWordgrid));
      }
    } else {
      if(diarects.includes(keyVal)){
        if (hosonto.includes(newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos].substr(-1))){
          return;
        } else {
          newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] += keyVal;
          dispatch(wordgridAction(newWordgrid));
        }
      } else if(hosonto.includes(keyVal)) {
        if (hosonto.includes(newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos].substr(-1))){
          return;
        } else {
          newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] += keyVal;
          dispatch(wordgridAction(newWordgrid));
        }
      } else {
        if (hosonto.includes(newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos].substr(-1))){
          newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] += keyVal;
          dispatch(wordgridAction(newWordgrid));
          
        } 
        else {
          setCurrAttempt({...currAttempt, alphabetPos: currAttempt.alphabetPos + 1});
          newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos + 1] = keyVal;
          dispatch(wordgridAction(newWordgrid));
        }
        
      }
    }
  }

  const onEnter = () => {

   const newWordgrid = wordgrid;


    if (newWordgrid[currAttempt.attemptVal][3] == "" | null) return;


    let currWord = "";
    for(let i = 0; i<4; i++){
      currWord += wordgrid[currAttempt.attemptVal][i];
    }

    if(wordSet.has(currWord)){

      if (currAttempt.attemptVal === 0){
        historyObject.date = date;
      }

      triedWords.words = triedWords.words + ", " + currWord;
      dispatch(triedWordsAction(triedWords));
      localStorage.setItem(userId + '-wordgrid', JSON.stringify(wordgrid));
      localStorage.setItem(userId + '-triedWords', JSON.stringify(triedWords));


      setCurrAttempt({attemptVal: currAttempt.attemptVal + 1, alphabetPos: 0});
      historyObject.currAttempt = {attemptVal: currAttempt.attemptVal + 1, alphabetPos: 0};

      alphabetAnimation(currWord);

    } else {
      setMessage("শব্দটি পাওয়া যায়নি");
      snackbar.className = "show";
      setTimeout(function(){ snackbar.className = snackbar.className.replace("show", ""); }, 3000);

      return;
    }
    
  }

  const onBackspace = () => {

   const newWordgrid = wordgrid;

    if(currAttempt.alphabetPos === 0){
      if (newWordgrid[currAttempt.attemptVal][0] == "" | null) return;
      else {
        newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] = "";
        dispatch(wordgridAction(newWordgrid));
      }
    } else if (currAttempt.alphabetPos === 4){
      newWordgrid[currAttempt.attemptVal][3] = "";
      dispatch(wordgridAction(newWordgrid));
      setCurrAttempt({...currAttempt, alphabetPos: 2});
    }
    else {
      newWordgrid[currAttempt.attemptVal][currAttempt.alphabetPos] = "";
      dispatch(wordgridAction(newWordgrid));
      setCurrAttempt({...currAttempt, alphabetPos: currAttempt.alphabetPos - 1});
    }
    
  }


  const getDateDifference = (current, previous) => {
    var day1 = new Date(previous); 
    var day2 = new Date(current);
    var difference= (Math.abs(day2-day1))/(1000 * 3600 * 24);
    return difference;
  };

  function waitforme(ms)  {
    return new Promise( resolve => { setTimeout(resolve, ms); });
  }; 

    async function alphabetAnimation(currWord)  {
    var count = 0;

    for (var i = ((historyObject.currAttempt.attemptVal - 1) * 4); i < historyObject.currAttempt.attemptVal * 4; i++){

      await waitforme(400);

      alphabetElement[i].classList.remove("clicked");
      void alphabetElement[i].offsetWidth;
      alphabetElement[i].classList.add("clicked"); 

      await waitforme(100);

      alphabetElement[i].id = positiongrid[historyObject.currAttempt.attemptVal - 1][count]; 
      count += 1;
    }

    if (currWord === shobdle["shobdle"].join("").toString()){

      const winSound = new Audio(winSoundFile); // ✅ Load the imported sound
      winSound.play();

      historyObject.played = (parseInt(historyObject.played) + 1).toString();
      historyObject.win = (parseInt(historyObject.win) + 1).toString();
      historyObject.winPer = Math.floor(((parseInt(historyObject.win) / parseInt(historyObject.played)) * 100)).toString();
      historyObject.currStreak = (parseInt(historyObject.currStreak) + 1).toString();
      historyObject.winDate = historyObject.date;

      if(parseInt(historyObject.currStreak) > parseInt(historyObject.maxStreak)){
        historyObject.maxStreak = parseInt(historyObject.currStreak).toString();
      }

      couponCode = generateBengaliCouponCode();
      historyObject.coupon = couponCode;

      
      dispatch(historyDataAction(historyObject));



      //Upload the play history
      await uploadPlayHistory(userId, historyObject.date, JSON.parse(localStorage.getItem(userId + '-playerName')), currAttempt.attemptVal  + 1 ,"win", couponCode);

      //Upload the coupon
      await saveCouponToFirestore(userInfo.displayName, couponCode);

      //Show Gameover Modal
      dispatch(gameoverAction(true))
      dispatch(historyAction(true));


      
      

      // setMessage("শুভেচ্ছা");
      // snackbar.className = "show";
      // setTimeout(
      //   function()
      //   { 
      //     snackbar.className = snackbar.className.replace("show", ""); 
      //     dispatch(historyAction(true));
      //   }, 3000);
      
    } 

    if (currAttempt.attemptVal === 6 && currWord !== shobdle["shobdle"].join("").toString()) {
      historyObject.played = (parseInt(historyObject.played) + 1).toString();
      historyObject.winPer = Math.floor(((parseInt(historyObject.win) / parseInt(historyObject.played)) * 100)).toString();
      historyObject.currStreak = "0";

      dispatch(historyDataAction(historyObject));

      const failSound = new Audio(failSoundFile); // ✅ Load the imported sound
      failSound.play();


      couponCode = "None";
      //Upload the play history
      await uploadPlayHistory(userId, historyObject.date, JSON.parse(localStorage.getItem(userId + '-playerName')), currAttempt.attemptVal + 1,"lose", couponCode);

      //Show Gameover Modal
      dispatch(gameoverAction(true));
      dispatch(historyAction(true));

      // Show Shobdle if the user fails to find the shobdle

      // setMessage(shobdle["shobdle"].join("").toString());
      // snackbar.className = "show";
      // setTimeout(
      //   function()
      //   { 
      //     snackbar.className = snackbar.className.replace("show", ""); 
      //     dispatch(historyAction(true));
      //   }, 3000);
    }
    localStorage.setItem(userId + '-history', JSON.stringify(historyObject));
  }

  useEffect(() => {
      // Set Tab Title
      document.title = `${userInfo.displayName || ""} - শব্দল | Shobdle`;

      // Update Open Graph meta tags dynamically
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogImage = document.querySelector('meta[property="og:image"]');

      if (ogTitle && ogImage) {
          ogTitle.content = `${userInfo.displayName || ""} এর আজকের শব্দলটি কী?`;
          ogImage.content = "https://shobdle.devs-core.com/shobdle.jpg";
      }
  }, [userInfo]);

  return (
  <div className="game" data-theme={historyObject.theme} data-contrast={historyObject.contrast ? 'contrast' : 'normal'}>
    <div className="shobdle__bg">
      <Navbar/>
      <AppContext.Provider value={{
      userId,
      currAttempt, 
      setCurrAttempt, 
      setMessage,
      onSelectKey, 
      onEnter, 
      onBackspace, 
      keyState
      }}>

      <div className="shobdle_body">
        {loading ? <ReactLoading type="bubbles" color="#0072ba"
                height={50} width={50} /> : null}
        <Wordgrid/>
        {isShobdleChecker ? <ShobdleChecker username={userInfo ? userInfo.displayName : ""}/> : null}
        {isHelp ? <Help/> : null}
        {isHistory ? <History/> : null}
        {isSettings ? <Settings/> : null}
        <div id="snackbar">{message}</div>
        <Keyboard/>
      </div>
      </AppContext.Provider>
      <FloatingButton/>
    </div>
    
  </div>
  );
}

export default Game;
