import React, { useState, useRef, useEffect } from "react";
import { BiHelpCircle } from "react-icons/bi";
import { useDispatch } from "react-redux";
import { helpAction } from "../../actions";
import "./floatingButton.css";

const FloatingButton = () => {
  const dispatch = useDispatch();
  const buttonRef = useRef(null);
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [dragged, setDragged] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  // Determine initial position based on screen size
  useEffect(() => {
    const updatePosition = () => {
      if (window.innerWidth <= 768) {
        // Center-right position for mobile/tablet
        setPosition({ x: window.innerWidth - 50, y: window.innerHeight / 3 });
      } else {
        // Bottom-right position for laptops
        setPosition({ x: window.innerWidth - 80, y: window.innerHeight - 80 });
      }
    };

    updatePosition();
    window.addEventListener("resize", updatePosition);

    return () => window.removeEventListener("resize", updatePosition);
  }, []);

  // Prevent scrolling when dragging starts
  const disableBodyScroll = () => {
    document.body.style.overflow = "hidden";
  };

  // Re-enable scrolling when dragging stops
  const enableBodyScroll = () => {
    document.body.style.overflow = "auto";
  };

  // Handle both mouse and touch start events
  const handleStart = (e) => {
    e.preventDefault(); 
    e.stopPropagation();
    setIsDragging(true);
    setDragged(false);
    disableBodyScroll();

    const clientX = e.type.includes("touch") ? e.touches[0].clientX : e.clientX;
    const clientY = e.type.includes("touch") ? e.touches[0].clientY : e.clientY;

    setOffset({
      x: clientX - position.x,
      y: clientY - position.y,
    });
  };

  // Handle both mouse and touch move events
  const handleMove = (e) => {
    if (!isDragging) return;
    e.preventDefault(); 

    const clientX = e.type.includes("touch") ? e.touches[0].clientX : e.clientX;
    const clientY = e.type.includes("touch") ? e.touches[0].clientY : e.clientY;

    let newX = clientX - offset.x;
    let newY = clientY - offset.y;

    // Adjust max width constraint based on screen size
    const maxWidth = window.innerWidth <= 768 ? window.innerWidth - 50 : window.innerWidth - 70;
    const maxHeight = window.innerWidth <= 768 ? window.innerHeight - 50 : window.innerHeight - 70;

    // Ensure the button stays within screen bounds
    newX = Math.max(10, Math.min(maxWidth, newX));
    newY = Math.max(10, Math.min(maxHeight, newY));

    setPosition({ x: newX, y: newY });
    setDragged(true);
  };

  // Handle both mouse and touch end events
  const handleEnd = () => {
    setIsDragging(false);
    enableBodyScroll();
    if (!dragged) {
      dispatch(helpAction(true));
    }
  };

  return (
    <div
      ref={buttonRef}
      className="floating-button"
      style={{ left: `${position.x}px`, top: `${position.y}px` }}
      onMouseDown={handleStart}
      onMouseMove={handleMove}
      onMouseUp={handleEnd}
      onTouchStart={handleStart}
      onTouchMove={handleMove}
      onTouchEnd={handleEnd}
    >
      <BiHelpCircle />
    </div>
  );
};

export default FloatingButton;