import React, { useState } from 'react';
import { FaRegUser } from "react-icons/fa";
import { BiHelpCircle } from 'react-icons/bi';
import { AiOutlineHistory } from 'react-icons/ai';
import { FiSettings } from 'react-icons/fi';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import dcLogo from '../../assets/DC Logo.png';
import dcLogoWhite from '../../assets/DC Logo White.png';
import { useDispatch, useSelector } from 'react-redux';
import { helpAction, historyAction, nameInputAction, settingsAction } from '../../actions';
import { useNavigate } from 'react-router-dom';
import './navbar.css';


const Navbar = () => {
  
  const [toggleMenu, setToggleMenu] = useState(false);
  const dispatch = useDispatch();
  const historyObject = useSelector(state => state.isHistoryData);
  const navigate = useNavigate();
  
  return (
    <div className="shobdle__navbar">
      <div className="shobdle__navbar-logos_dc-logo">
            <img src={historyObject.theme === 'dark' ? dcLogoWhite : dcLogo} onClick={() => HandleNavClick("https://devs-core.com/")} alt="Devs Core"/>
        </div>
        <div className="shobdle__navbar-logos_logo" onClick={() => navigate('/')}>
          <p>শব্দল</p>
       </div>
        <div className="shobdle__navbar-menu">
          <AiOutlineHistory size={27} onClick={() => dispatch(historyAction(true))} title='ফলাফল'/>
          <FiSettings size={27} onClick={() => dispatch(settingsAction(true))} title='সেটিংস'/>
        </div>
        <div className="shobdle__navbar-phone_menu">
          {toggleMenu
            ? <RiCloseLine size={27} onClick={() => setToggleMenu(false)} />
            : <RiMenu3Line size={27} onClick={() => setToggleMenu(true)} />}
          {toggleMenu && (
          <div className="shobdle__navbar-phone_menu_container scale-up-center">
            <div className="shobdle__navbar-phone_menu_container-links">
              <p onClick={() => {dispatch(historyAction(true)); setToggleMenu(false);}}>ফলাফল</p>
              <p onClick={() => {dispatch(settingsAction(true)); setToggleMenu(false);}}>সেটিংস</p>
            </div>
          </div>
          )}
        </div>
    </div>
  );
};

function HandleNavClick (props)  {
  window.location.assign(props);      
}

export default Navbar;
