import React, { useRef, useEffect, useMemo, useState } from 'react'
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { useDispatch } from 'react-redux';
import { historyAction } from '../../actions';
import { useSelector } from 'react-redux';
import ReactDOM  from 'react-dom';
import { useNavigate, useParams } from 'react-router-dom';
import { StatusIcon, Coupon } from '../../components';
import { getPlayHistory } from '../../helpers/firebase';
import DataTable from 'react-data-table-component';
import * as htmlToImage from 'html-to-image';
import html2canvas from "html2canvas";
import './history.css';


function History() {
    const { userId } = useParams();
    const dispatch = useDispatch();
    let today = new Date();
    var date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
    var snackbar = document.getElementById("history-snackbar");
    const [message, setMessage] = useState("");

    const ref = useRef(null);
    const isHistory = useSelector(state => state.isHistory);
    const historyObject = useSelector(state => state.isHistoryData);
    const positiongrid = useSelector(state => state.isPositiongrid);
    const userInfo = JSON.parse(localStorage.getItem(userId + '-creatorInfo'));
    const [playHistory, setPlayHistory] = useState([]);
    const navigate = useNavigate();
    const [result, setResult] = useState("");
    const isWebShareSupported = (navigator.canShare && navigator.share);
    const [generating, setGenerating] = useState(false);

    //Style of the Latest Jobs Table
  const tableStyle = {
    headCells: {
      style: {
        fontSize: "16px",
        paddingTop: "12px",
        paddingBottom: "12px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "18px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#F4F7FF",
        borderBottomColor: "#CEDDFF",
        outline: "1px solid #CEDDFF",
      },
    },
  };

  //Columns of the Latest Jobs Table
  const columns = useMemo(
    () => [
      {
        id: "status",
        cell: (row) => (
          <StatusIcon
            status={row.status}
          />
        ),
        width: "20px",
        ignoreRowClick: true,
      },
      {
        id: "name",
        name: "খেলোয়াড়",
        selector: (row) => row.name,
        sortable: true,
        wrap: true,
      },
      {
        id: "attempts",
        name: "চেষ্টার সংখ্যা",
        selector: (row) => convertToBengaliNumbers(row.attempts),
        sortable: true,
        center: true,
      },
    ],
    []
  );

    useEffect(() => {
        let newResult = "";
        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++) {
            for (var j = 0; j < 4; j++) {
                if (positiongrid[i][j] === "absent") newResult += "⬛ ";
                if (positiongrid[i][j] === "wpwl") newResult += "🟪 ";
                if (positiongrid[i][j] === "rpwl") newResult += "🟦 ";
                if (positiongrid[i][j] === "wprl") newResult += "🟨 ";
                if (positiongrid[i][j] === "rprl") newResult += "🟩 ";
            }
            newResult += "\n";
        }
        setResult(newResult);

        const fetchPlayHistory = async () => {
            const historyData = await getPlayHistory(userId, date);
            setPlayHistory(Object.values(historyData));
          };

        const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            dispatch(historyAction(false));
        }
        };
        // Fetch play history
        fetchPlayHistory();

        document.addEventListener('click', handleClickOutside, true);
        return () => {
        document.removeEventListener('click', handleClickOutside, true);
        };
    }, [ isHistory ]);

    const share = () => {
        var shareResult = "";

        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++){
            for (var j = 0; j < 4; j++){
                if (positiongrid[i][j] === "absent") shareResult += "⬛";
                if (positiongrid[i][j] === "wpwl") shareResult += "🟪";
                if (positiongrid[i][j] === "rpwl") shareResult += "🟦";
                if (positiongrid[i][j] === "wprl") shareResult += "🟨";
                if (positiongrid[i][j] === "rprl") shareResult += "🟩";
            }
            shareResult += "\n";
        }
        shareResult = userInfo.displayName + " এর শব্দল " + historyObject.date + "\n\n" + shareResult;

        copyToClipboard(shareResult);

        setMessage("কপি করা হয়েছে");
        snackbar.className = "show";
        setTimeout(function(){ snackbar.className = snackbar.className.replace("show", ""); }, 3000);
    };

    const shareWithWebShare = async (event) => {
      event.stopPropagation();
      setGenerating(true);
      // Get the node to share
      const node = document.getElementById("share-node");
      const useClone = !isMobileOrTablet();
      let targetNode = node;

      var shareResult = "";

        for (var i = 0; i < historyObject.currAttempt.attemptVal; i++){
            for (var j = 0; j < 4; j++){
                if (positiongrid[i][j] === "absent") shareResult += "⬛";
                if (positiongrid[i][j] === "wpwl") shareResult += "🟪";
                if (positiongrid[i][j] === "rpwl") shareResult += "🟦";
                if (positiongrid[i][j] === "wprl") shareResult += "🟨";
                if (positiongrid[i][j] === "rprl") shareResult += "🟩";
            }
            shareResult += "\n";
        }
        shareResult = userInfo.displayName + " এর শব্দল " + historyObject.date + "\n\n" + shareResult;


      // Generate the image
      try {
        const clone = node.cloneNode(true);


        if (useClone) {
          targetNode = clone;
          const downloadButtons = clone.querySelectorAll('.download-button');
          downloadButtons.forEach(btn => btn.remove());
  
          const couponCodes = clone.querySelectorAll('.coupon-code');
          couponCodes.forEach(code => code.remove());
  
          const backgroundImageURL = window.location.origin + '/history-bg.png';
          clone.style.background = `url(${backgroundImageURL}) no-repeat center center / cover`;
          
          // ✅ Ensure Correct Dimensions for Image Capture
          clone.style.width = "690px";
          clone.style.height = "955px";
  
          const container = document.createElement("div");
          container.style.position = "fixed";
          container.style.top = "0";
          container.style.left = "0";
          container.style.width = "690px";
          container.style.height = "955px";
          container.style.opacity = "0"; // Hide it
          container.style.pointerEvents = "none";
          container.appendChild(clone);
          document.body.appendChild(container);
        }


      // ✅ Ensure Styles Apply Before Capturing
        await new Promise(resolve => setTimeout(resolve, 500));

        // ✅ Generate Image Using `html2canvas`
        const canvas = await html2canvas(targetNode, {
          useCORS: true, // Ensures external images load properly
          logging: false,
          scale: 2, // Ensures high resolution
          backgroundColor: null, // Keeps transparency if needed
        });

        // ✅ Remove Temporary Container
        if (useClone) {
          document.body.removeChild(targetNode.parentNode);
        }

        // ✅ Convert Canvas to Blob
        canvas.toBlob((blob) => {
          if (blob) {
            forceDownload(blob, "shobdle-result.png");
          } else {
            throw new Error("Image generation failed.");
          }
        }, "image/png");
    
      } 
      catch (error) {
        console.error('Error generating image:', error);
        share();
      } finally {
        setGenerating(false); // Set generating state to false when image generation is complete
      }
    };

    const shareWithWebShare2 = async (event) => {
      event.stopPropagation();
      setGenerating(true);
    
      const node = document.getElementById("share-node");
      const isLaptop = !isMobileOrTablet();
    
      try {
        let targetNode = node;
    
        if (isLaptop) {
          const clone = node.cloneNode(true);
          targetNode = clone;
    
          // ✅ Remove Unwanted Elements from Clone
          const downloadButtons = clone.querySelectorAll(".download-button");
          downloadButtons.forEach((btn) => btn.remove());
    
          const couponCodes = clone.querySelectorAll(".coupon-code");
          couponCodes.forEach((code) => code.remove());
    
          // ✅ Ensure Correct Dimensions for Image Capture
          clone.style.width = "690px";
          clone.style.height = "955px";
    
          // ✅ Create a Temporary Container for Rendering Clone
          const container = document.createElement("div");
          container.style.position = "fixed";
          container.style.top = "0";
          container.style.left = "0";
          container.style.width = "690px";
          container.style.height = "955px";
          container.style.opacity = "0"; // Hide it
          container.style.pointerEvents = "none";
          container.appendChild(clone);
          document.body.appendChild(container);
    
          // ✅ Ensure Styles Apply Before Capturing
          await new Promise(resolve => setTimeout(resolve, 500));
    
          targetNode = clone;
        } 
        // else {
        //   // ✅ Temporarily Remove Classes from Original Node
        //   const downloadButtons = node.querySelectorAll(".download-button");
        //   downloadButtons.forEach((btn) => btn.remove());
    
        //   const couponCodes = node.querySelectorAll(".coupon-code");
        //   couponCodes.forEach((code) => code.remove());

    
        //   // ✅ Allow DOM to Update Before Capturing
        //   await new Promise(resolve => setTimeout(resolve, 500));
        // }
    
        // ✅ Generate Image Using `html2canvas`
        const canvas = await html2canvas(targetNode, {
          useCORS: true,
          logging: false,
          scale: 2,
          backgroundColor: null,
        });
    
        // ✅ Remove Temporary Clone if Used
        if (isLaptop) {
          document.body.removeChild(targetNode.parentNode);
        } 
        // else {
        //   // ✅ Restore Classes After Image is Captured
        //   node.classList.add("download-button", "coupon-code");
        // }
    
        // ✅ Convert Canvas to Blob & Download
        canvas.toBlob((blob) => {
          if (blob) {
            forceDownload(blob);
          } else {
            throw new Error("Image generation failed.");
          }
        }, "image/png");
    
      } catch (error) {
        console.error("Error generating image:", error);
        alert("Failed to generate the image. Please try again.");
      } finally {
        setGenerating(false);
      }
    };
    


    // ✅ Function to Detect if User is on Mobile or Tablet
    const isMobileOrTablet = () => {
      return /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    };

    /**
     * ✅ Force Download Image and Save in Mobile File Manager
     */
    const forceDownload = (blob, name) => {
      const reader = new FileReader();
      reader.onload = function () {
        const link = document.createElement("a");
        link.href = reader.result;
        link.download = name;
        document.body.appendChild(link);
        
        // ✅ Fix Mobile Download Issue
        link.click();
        setTimeout(() => {
          document.body.removeChild(link);
        }, 100);
      };
      reader.readAsDataURL(blob);
    };

    const downloadCouponImage = async () => {
      try {
        // Use the correct element id. In your History.jsx, the Coupon is rendered within a div with id "couponr"
        const node = document.getElementById("coupon");
        if (!node) {
          alert("Coupon container not found!");
          return;
        }
        
        const canvas = await html2canvas(node, {
          useCORS: true, // Ensures external images load properly
          logging: false,
          scale: 2, // Ensures high resolution
          backgroundColor: null, // Keeps transparency if needed
        });

        // Convert the coupon element to an image blob.
        // Here we use a white background to ensure transparency doesn't cause issues.
        
        // Create a temporary URL for the blob and trigger a download.
        canvas.toBlob((blob) => {
          if (blob) {
            forceDownload(blob, "shobdle-coupon.png");
          } else {
            throw new Error("Image generation failed.");
          }
        }, "image/png");

      } catch (error) {
        console.error("Error generating coupon image:", error);
      }
    };


    function copyToClipboard(text) {
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            return window.clipboardData.setData("Text", text);
    
        }
        else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {

            var textarea = document.createElement("textarea");
           // textarea.textContent = text;
            textarea.value = text;
            textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                return document.execCommand("copy");  // Security exception may be thrown by some browsers.
            }
            catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                return prompt("Copy to clipboard: Ctrl+C, Enter", text);
            }
            finally {
                document.body.removeChild(textarea);
            }
        }
    }

    // Function to convert English numbers to Bengali numbers
    const convertToBengaliNumbers = (num) => {
      const bengaliDigits = ['০', '১', '২', '৩', '৪', '৫', '৬', '৭', '৮', '৯'];
      return num.toString().replace(/\d/g, (digit) => bengaliDigits[digit]);
    };
     

    return ReactDOM.createPortal(
        <div className="history_bg" data-theme={historyObject.theme}>
            <div ref={ref} className="history">
                <div className= "history-close-icon">
                    <AiOutlineCloseCircle size={27} onClick={() => dispatch(historyAction(false))}/>
                </div>
                {/* style={{ position: "relative"}} */}
                <div className='share-node' id='share-node' style={{ position: "relative"}}>
                    <img 
                        src={window.location.origin + "/history-bg.png"} 
                        alt="Background"
                        style={{
                            position: "absolute",
                            top: 0,
                            left: 0,
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            zIndex: -1 // Ensure it stays behind other elements
                        }} 
                    />
                  <div className="history-body">
                      <h1 className='name'>{userInfo.displayName}</h1>
                      <h1 className='sub-text'>{"এর শব্দলে আমার ফলাফল"}</h1>
                      <div className='bar'></div>
                      {historyObject.coupon && (
                        <div>
                          <div className="coupon" id="coupon">
                           <Coupon code={historyObject.coupon} date={historyObject.winDate} />
                          </div>
                          <button className='download-button' onClick={downloadCouponImage}>
                            ডাউনলোড কুপন ⏬
                          </button>
                        </div>
                      )}
                      {/* <div className="history-win">
                                <p>জিতার %</p>
                                <div className="container">
                                  <div className="text-overlay">
                                    {historyObject.winPer}
                                    <p>%</p>
                                  </div>
                                </div>
                            </div> */}
                      <div className="stats">
                          <div className="played">
                              <h2>{convertToBengaliNumbers(historyObject.played)}</h2>
                              <p>খেলা হয়েছে</p>
                          </div>
                          <div className="currStreak">
                                <h2>{convertToBengaliNumbers(historyObject.currStreak)}</h2>
                                <p>বর্তমান স্ট্রীক</p>
                            </div>
                            <div className="maxStreak">
                                <h2>{convertToBengaliNumbers(historyObject.maxStreak)}</h2>
                                <p>সর্বোচ্চ স্ট্রীক</p>
                            </div>
                      </div>
                      <div className="history-result">
                          {result.split('\n').map((line, index) => (
                              <React.Fragment key={index}>
                                  {line}
                                  <br />
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                </div>
                <div className='history-body'>
                  <div className="history-disclaimer">
                      <p>{userInfo.displayName + " কে মেনশন দিয়ে শেয়ার কর তোমার ফলাফল"}</p>
                  </div>
                  <button className='share-button' onClick={(e) => shareWithWebShare(e)}>
                    {generating ? 'Generating...' : 'শেয়ার ↗️'} 
                  </button>
                  <p className="history-table-title">
                  {userInfo.displayName + " এর শব্দল আজকে খেলেছে " + convertToBengaliNumbers(playHistory.length) + " জন"}
                  </p>
                  <div className='bar'></div>
                  {playHistory.length > 0 ? (
                  <div className="history-table">
                      <DataTable
                      data={playHistory}
                      columns={columns}
                      customStyles={tableStyle}
                      persistTableHead
                      highlightOnHover
                      defaultSortFieldId="attempts"
                      defaultSortAsc={true}
                      />
                  </div>
                  ) : null}
                  <button className="history-home-button" onClick={() => navigate('/')}>
                  আমার শব্দল 😎
                  </button>
                  <div id="history-snackbar">{message}</div>
                </div>
            </div>

        </div>,
        document.getElementById('portal')
      );
}

export default History;