// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
import { getAuth, signInAnonymously } from "firebase/auth";
import { getFirestore, collection, doc, addDoc, getDoc, setDoc, getDocs  } from "firebase/firestore";
import { APPID, FBAPIKEY, FBAUTHDOMAIN, MEASUREMENTID, MESSAGESENDERID, PROJECTID, STORAGEBUCKET } from "./Words";
import { shobdleAction } from "../actions";


// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: FBAPIKEY,
  authDomain: FBAUTHDOMAIN,
  projectId: PROJECTID,
  storageBucket: STORAGEBUCKET,
  messagingSenderId: MESSAGESENDERID,
  appId: APPID,
  measurementId: MEASUREMENTID
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

export const auth = getAuth();
//Function to check if the user is signed in
export const isSignedIn = () => {
  return auth.currentUser;
};


export async function signAnonimously({displayName}) {
  return new Promise(async (resolve, reject) => {
    try {
      const result = await signInAnonymously(auth);

      // The signed-in user info.
      const user = result.user;
      logEvent(analytics, 'user_signed_in');

      // Upload user data to Firestore under "users" collection and user's UID document
      const userDocRef = doc(collection(db, "users"), user.uid);

    
      await setDoc(userDocRef, {
        displayName: displayName,
        // Add any other user data you want to store
      });
      resolve(user); // Resolve with user data or any other relevant information
    } catch (error) {
      // Handle Errors here.
      const errorCode = error.code;
      const errorMessage = error.message;

      reject(error); // Reject with the error object
    }
  });
};


  // Upload shobdle of each user
  export const uploadShobdle = async (userId, shobdle) => {
    try {
      // Create a reference to the shobdles collection
      const shobdlesCollectionRef = collection(db, "shobdles");
  
      // Get today's date in DD-MM-YYYY format
      const today = new Date();
      const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
  
      // Create a reference to the user's document
      const userDocRef = doc(shobdlesCollectionRef, userId);
  
      // Create a reference to the document within user's document using today's date
      const todayShobdleDocRef = doc(userDocRef, "dates", formattedDate);
  
      // Set the shobdle object as the data for the document
      await setDoc(todayShobdleDocRef, shobdle);
      logEvent(analytics, 'new_shobdle_uploaded');
      return true; // Resolve the Promise with true
    } catch (error) {
      return false; // Reject the Promise with false
    }
  };

  export const getShobdle = async (userId, date, shobdle, dispatch) => {
    try {
      // Create a reference to the shobdles collection
      const shobdlesCollectionRef = collection(db, "shobdles");
  
      // Create a reference to the user's document
      const userDocRef = doc(shobdlesCollectionRef, userId);
  
      // Create a reference to the document within user's document using the given date
      const shobdleDocRef = doc(userDocRef, "dates", date);
  
      // Get the shobdle object from the document
      const shobdleSnapshot = await getDoc(shobdleDocRef);
  
      if (shobdleSnapshot.exists()) {
        shobdle["shobdle"][0] = shobdleSnapshot.data()["0"];
        shobdle["shobdle"][1] = shobdleSnapshot.data()["1"];
        shobdle["shobdle"][2] = shobdleSnapshot.data()["2"];
        shobdle["shobdle"][3] = shobdleSnapshot.data()["3"];

        dispatch(shobdleAction(shobdle));
        return true; // Resolve the Promise with true
      } else {
        // Document doesn't exist for the given date
        console.log("No such document!");
        return false; // Resolve the Promise with false
      }
    } catch (error) {
      // Handle any errors during the retrieval
      console.error("Error during shobdle retrieval:", error);
      return null;
    }
  };
  
  // Get User Info
  export async function getUserInfo(uid) {
    try {
      // Create a reference to the user's document using the UID
      const userDocRef = doc(collection(db, 'users'), uid);
  
      // Get the document snapshot
      const userDocSnap = await getDoc(userDocRef);
  
      // Check if the document exists
      if (userDocSnap.exists()) {
        // Access the data
        return userDocSnap.data();
      } else {
        console.log("User document does not exist.");
        return null;
      }
    } catch (error) {
      console.error("Error getting display name:", error.message);
      return null;
    }
  };

  // Function to update displayName in "users" collection or store in localStorage
  export const updateDisplayName = async (newDisplayName) => {
    try {
      // Check if the user is signed in
      if (isSignedIn()) {
        const uid = auth.currentUser.uid; // Get the UID of the currently signed-in user
        // Create a reference to the user's document using the UID
        const userDocRef = doc(collection(db, 'users'), uid);
  
        // Update the displayName field in the document
        await setDoc(userDocRef, { displayName: newDisplayName }, { merge: true });
        localStorage.setItem('displayName', JSON.stringify(newDisplayName));
      } else {
        // User is not signed in, store the displayName in localStorage
        localStorage.setItem('displayName', JSON.stringify(newDisplayName));
      }
    } catch (error) {
      console.error("Error updating display name:", error.message);
    }
  };

  // Upload play history
  export const uploadPlayHistory = async (userId, date, name, attempts,status, coupon) => {
    try {
      // Create a reference to the playHistory collection for the user
      const playHistoryCollectionRef = collection(db, "playHistory");
  
      // Create a reference to today's date under the user's playHistory collection
      const todayPlayHistoryCollectionRef = collection(playHistoryCollectionRef, userId, date);
  
      // Add a new document with an autogenerated ID
      await addDoc(todayPlayHistoryCollectionRef, {
        name: name,
        attempts: attempts,
        status: status,
        coupon: coupon,
      });
      logEvent(analytics, 'new_play_history_uploaded');
  
      return true; // Resolve the Promise with true
    } catch (error) {
      console.error("Error during play history upload:", error);
      return false; // Reject the Promise with false
    }
  };

  // Get play history
  // Fetch play history for the user
  export const getPlayHistory = async (userId, date) => {
    try {
        // Create a reference to the playHistory collection for the user
        const playHistoryCollectionRef = collection(db, "playHistory");

        // Get today's play history collection
        const todayPlayHistoryCollectionRef = collection(playHistoryCollectionRef, userId, date);

        // Get play history documents
        const querySnapshot = await getDocs(todayPlayHistoryCollectionRef);

        // Process the documents and extract data
        const playHistory = [];
        querySnapshot.forEach((doc) => {
            playHistory.push({
                name: doc.data().name,
                attempts: doc.data().attempts,
                status: doc.data().status,
                coupon: doc.data().coupon,
            });
        });
        return playHistory;
    } catch (error) {
        console.error("Error during play history fetch:", error);
        return [];
    }
  };


  /////////////////////////////////////////////////////////////
// 🔹 Function to Store Coupon in Firestore When User Wins //
/////////////////////////////////////////////////////////////

export const saveCouponToFirestore = async (username, couponCode) => {
  const today = new Date();
  const formattedDate = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;

  

  try {
    // Reference to "coupons" collection
    const couponsRef = collection(db, "coupons");

    // Reference to today's document
    const todayDocRef = doc(couponsRef, formattedDate);

    // Reference to the "entries" sub-collection under today's document
    const couponEntryRef = collection(todayDocRef, "entries");

    // Add the coupon as a new document in the "entries" sub-collection
    await addDoc(couponEntryRef, {
      couponCode: couponCode,
      username: username,
      timestamp: new Date()
    });
  } catch (error) {
    console.error("❌ Error saving coupon to server:", error);
  }
};

