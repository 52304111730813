import React, { useState, useEffect, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ReactLoading from "react-loading";
import {
  isSignedIn,
  getUserInfo,
  getPlayHistory,
  getShobdle,
  signAnonimously,
} from "../../helpers/firebase";
import { StatusIcon, Timer } from "../../components";
import DataTable from "react-data-table-component";
import "./profile.css";


function Profile() {
  const { userId } = useParams();
  const [userInfo, setUserInfo] = useState(new Set());
  const [newName, setNewName] = useState(
    JSON.parse(localStorage.getItem( userId + "-playerName")) || ""
  );
  const [isCreator, setIsCreator] = useState(false);
  const [playHistory, setPlayHistory] = useState([]);

  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const dispatch = useDispatch();
  var snackbar = document.getElementById("snackbar");
  const shobdle = useSelector((state) => state.isShobdle);
  const [inputValues, setInputValues] = useState(['', '', '', '']);
  const today = new Date();
  const date = `${today.getDate()}-${today.getMonth() + 1}-${today.getFullYear()}`;
//   const inputValues = Object.values(shobdle);
  const [loading, setLoading] = useState(true);
  const [liveShobdle, setLiveShobdle] = useState(false);

  //Style of the Latest Jobs Table
  const tableStyle = {
    headCells: {
      style: {
        fontSize: "16px",
        paddingTop: "12px",
        paddingBottom: "12px",
        fontWeight: "bold",
      },
    },
    cells: {
      style: {
        fontSize: "18px",
      },
    },
    rows: {
      highlightOnHoverStyle: {
        backgroundColor: "#F4F7FF",
        borderBottomColor: "#CEDDFF",
        outline: "1px solid #CEDDFF",
      },
    },
  };

  //Columns of the Latest Jobs Table
  const columns = useMemo(
    () => [
      {
        id: "status",
        cell: (row) => (
          <StatusIcon
            status={row.status}
          />
        ),
        width: "20px",
        ignoreRowClick: true,
      },
      {
        id: "name",
        name: "খেলোয়াড়",
        selector: (row) => row.name,
        sortable: true,
        wrap: true,
      },
      {
        id: "attempts",
        name: "চেষ্টার সংখ্যা",
        selector: (row) => row.attempts,
        sortable: true,
        center: true,
      },
    ],
    []
  );

  // Get the current date
    const currentDate = new Date();

    // Set the target date to midnight of the next day
    const targetDate = new Date(currentDate);
    targetDate.setDate(currentDate.getDate() + 1);
    targetDate.setHours(0, 0, 0, 0);

    // Calculate the time difference between the current time and midnight
    const timeDifference = targetDate.getTime() - currentDate.getTime();

    // Create a new Date object with the calculated time difference
    const expiryTime = new Date(currentDate.getTime() + timeDifference);

    // Convert the difference to hours, minutes, and seconds
    const hours = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);


  const handleNameChange = (event) => {
    // Update the local state when the user types in the input field
    setNewName(event.target.value);
  };

  const convertToBengali = (num) => {
    const englishToBengali = {
      '0': '০',
      '1': '১',
      '2': '২',
      '3': '৩',
      '4': '৪',
      '5': '৫',
      '6': '৬',
      '7': '৭',
      '8': '৮',
      '9': '৯',
    };
    return num.toString().replace(/[0-9]/g, (digit) => englishToBengali[digit]);
  };

  const handleGameStart = async (event) => {
    if (isCreator) {

          // Convert hours, minutes, and seconds to Bengali numerals
    const bengaliHours = convertToBengali(hours);
    const bengaliMinutes = convertToBengali(minutes);
    const bengaliSeconds = convertToBengali(seconds);

      // Handle copy link functionality
      const linkToCopy = `চ্যালেঞ্জ: খুঁজে বের করো আমার আজকের শব্দল, সময় বাকি আর মাত্র ${bengaliHours} ঘণ্টা, ${bengaliMinutes} মিনিট, ${bengaliSeconds} সেকেন্ড - https://shobdle.devs-core.com/profile/${userId}`;
      copyToClipboard(linkToCopy);
      setMessage("কপি হয়েছে");
      snackbar.className = "show";
      setTimeout(function () {
        snackbar.className = snackbar.className.replace("show", "");
      }, 3000);
    } else {
      if (newName.trim() === "") {
        setError("আপনার নাম দিন");
        return;
      }
      // 🔹 Corrected: Check if the user is signed in
      try {
        setLoading(true); // Show "Loading..." on button

        const user = await isSignedIn();
        if (!user) {
            await signAnonimously({ displayName: newName });
        }

        localStorage.setItem(userId + "-playerName", JSON.stringify(newName));
        navigate(`/shobdle/${userId}`);
      } catch (error) {
          console.error("Error signing in:", error);
          setError("সাইন ইন করতে সমস্যা হয়েছে, আবার চেষ্টা করুন!");
      } finally {
          setLoading(false); // Hide "Loading..." after authentication
      }    
  }
  };

  function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
      // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
      return window.clipboardData.setData("Text", text);
    } else if (
      document.queryCommandSupported &&
      document.queryCommandSupported("copy")
    ) {
      var textarea = document.createElement("textarea");
      // textarea.textContent = text;
      textarea.value = text;
      textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
      document.body.appendChild(textarea);
      textarea.select();
      try {
        return document.execCommand("copy"); // Security exception may be thrown by some browsers.
      } catch (ex) {
        console.warn("Copy to clipboard failed.", ex);
        return prompt("Copy to clipboard: Ctrl+C, Enter", text);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  }

  useEffect(() => {
    const checkAuthentication = async () => {
        const user = isSignedIn();
        if (user && userId === user.uid) {
          setIsCreator(true);
          // Get Today's Shobdle
          const shobdleSuccess = await getShobdle(userId, date, shobdle, dispatch);
          if (shobdleSuccess) {
            setInputValues(Object.values(shobdle["shobdle"]));
            setLiveShobdle(true);
          };
        } else {
          setIsCreator(false);
        }
      };

      const fetchPlayHistory = async () => {
        const historyData = await getPlayHistory(userId, date);
        setPlayHistory(Object.values(historyData));
      };

    const fetchData = async () => {
      try {
        // Fetch user info
        const userData = await getUserInfo(userId);

        if (!userData) {
          // User document does not exist or is null
          navigate("/");
          return;
        }

        // User data is available
        setUserInfo(userData);

        // Check authentication
      await checkAuthentication();

      // Fetch play history
      await fetchPlayHistory();

      setLoading(false);
      } catch (error) {
        console.error("Error during initialization:", error.message);
        setLoading(false);
        // Handle the error, e.g., show an error message or take appropriate action
      }
    };

    // Call the async function immediately
    fetchData();
  }, []);

  useEffect(() => {
      // Set Tab Title
      document.title = `${userInfo.displayName || ""} - শব্দল | Shobdle`;

      // Update Open Graph meta tags dynamically
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogImage = document.querySelector('meta[property="og:image"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');

      if (ogTitle) {
        ogTitle.content = `${userInfo.displayName || ""} এর আজকের শব্দলটি কী?`;
      }
      if (ogImage) {
        ogImage.content = "https://opengraph.b-cdn.net/production/images/b8429974-e2b8-486f-a12e-44b6f40c9783.jpg?token=DKeg_SfvuFmYti4vuDb4BrDcCSNqDlAOrjaNmZ45_fA&height=563&width=1000&expires=33273312577"; // Replace with the correct image URL
      }
      if (ogDescription) {
        ogDescription.content = "শব্দল একটি বাংলা শব্দ খেলার অ্যাপ, যেখানে আপনি আপনার বন্ধুদের চ্যালেঞ্জ করতে পারেন।";
      }  }, [userInfo]);

  return (
    <div className="profile">
      <div className="profile-body">
        {loading ? (
          // Show loading spinner while data is being fetched
          <div className="loading-spinner">
            <ReactLoading type="bubbles" color="#0072ba" height={50} width={50} />
          </div>
        ) : (
          <>
            <p className="shobdle_logo">শব্দল</p>
            {!isCreator && (
                <div className="profile-input-row">
                {inputValues.map((value, index) => (
                    <input
                    key={index}
                    type="text"
                    placeholder="?"
                    className="profile-input-field"
                    readOnly
                    />
                ))}
                </div>
            )}
            <h1 className="title">
            {isCreator && liveShobdle
                ? "তোমার শব্দল প্রস্তুত 👇"
                : isCreator && !liveShobdle
                ? "তোমার আজকের শব্দল দাওনি এখনো 🥺"
                : `বলতে পারবে ${userInfo.displayName} এর শব্দলটি কী?`}
            </h1>
            {isCreator && (
                <div className="profile-input-row">
                {inputValues.map((value, index) => (
                    <input
                    key={index}
                    type="text"
                    value={value}
                    placeholder="?"
                    className="profile-input-field"
                    readOnly
                    />
                ))}
                </div>
            )}
            {isCreator && liveShobdle && (
              <div className="profile-disclaimer">
                <p>শব্দলটি প্রকাশিত আছে</p>
              </div>
            )}
            {isCreator && liveShobdle && (
                <div>
                    <Timer expiryTimestamp={expiryTime} />
                </div>
            )}
            {isCreator && liveShobdle && (
              <div className="profile-disclaimer">
                <p>লিংকটি তোমার বন্ধুদের সাথে শেয়ার করো</p>
              </div>
            )}
            {!isCreator && (
              <input
                type="text"
                value={newName}
                onChange={handleNameChange}
                placeholder="তোমার নাম"
                className="profile-input"
              />
            )}
            {isCreator && liveShobdle && (
              <input
                type="text"
                value={`https://shobdle.devs-core.com/profile/${userId}`}
                readOnly
                className="profile-input"
              />
            )}
            {isCreator && liveShobdle && (
              <button className="start-button" onClick={handleGameStart}>
              কপি করো
            </button>
            )
            }
            {!isCreator && (
              <button className="start-button" onClick={handleGameStart}>
              খেলা শুরু 👀
              </button>
            )
            }
            {error && <p className="error-message">{error}</p>}

            {isCreator && liveShobdle && (
              <h1 className="profile-table-title">
              {"তোমার শব্দল আজকে খেলেছে " + playHistory.length + " জন"}
              </h1>
            )
            }
            {!isCreator && (
              <h1 className="profile-table-title">
              {userInfo.displayName + " এর শব্দল আজকে খেলেছে " + playHistory.length + " জন"}
              </h1>
            )
            }
            
            {playHistory.length > 0 ? (
              <div className="profile-table">
                <DataTable
                  data={playHistory}
                  columns={columns}
                  customStyles={tableStyle}
                  persistTableHead
                  highlightOnHover
                  defaultSortFieldId="attempts"
                  defaultSortAsc={true}
                />
              </div>
            ) : null}
            <button className="home-button" onClick={() => navigate('/')}>
              আমার শব্দল দেই 😎
            </button>
          </>
        )}
      </div>
      <div id="snackbar">{message}</div>
    </div>
  );
}

export default Profile;
