import React, { useEffect, useContext, useCallback } from 'react';
import Key from "../../components/key/Key";
import { BsShift, BsArrowReturnLeft } from 'react-icons/bs';
import { TiBackspaceOutline } from 'react-icons/ti';
import { AppContext } from "../../pages/game/Game";
import { useSelector } from 'react-redux';
import "./keyboard.css";



function Keyboard() {

    const {currAttempt, onSelectKey, onEnter, onBackspace} = useContext(AppContext);

    const line1 = ["া", "ি", "ু", "ে", "ো", "্", "ঁ"];
    const line1_back = ["ং", "ী", "ূ", "ৈ", "ৌ", "্য", "ৃ"];
    const line2 = ["অ", "ই", "উ", "ক", "চ", "ট", "ত", "প", "ৎ"];
    const line2_back = ["আ", "ঈ", "ঊ", "খ", "ছ", "ঠ", "থ", "ফ", "ঃ"];
    const line3 = ["এ", "ও", "ঋ", "গ", "জ", "ড", "দ", "ব", "ড়"];
    const line3_back = ["ঐ", "ঔ", "ল", "ঘ", "ঝ", "ঢ", "ধ", "ভ", "ঢ়"];
    const line4 = ["ঙ", "ন", "ম", "স", "য", "র"];
    const line4_back = ["ঞ", "ণ", "শ", "ষ", "হ", "য়"];

    const keyState = useSelector(state => state.isKeyStateUpdated);
    const gameoverState = useSelector(state => state.isGameover);
    const toggleKeyboard = useSelector(state => state.isShiftClicked);
    const historyObject = useSelector(state => state.isHistoryData);



    const handleKeyboard = useCallback(
        (event) => {
          if(gameoverState) return;

          if (event.key === "Enter") {
            onEnter();
          } else if (event.key === "Backspace") {
            onBackspace();
          } else {
            line1.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line1_back.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line2.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line2_back.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line3.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line3_back.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line4.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
            line4_back.forEach((key) => {
                if (event.key.toLowerCase() === key.toLowerCase()) {
                onSelectKey(key);
                }
            });
          }
        },
        [currAttempt]
    );

    useEffect(() => {
        document.addEventListener("keydown", handleKeyboard);
    
        return () => {
          document.removeEventListener("keydown", handleKeyboard);
        };
    }, [handleKeyboard]);

  return (
    <div className="keyboard" onKeyDown={handleKeyboard} data-contrast={historyObject.contrast ? 'contrast' : 'normal'}> 
        <div className="line1">
            { toggleKeyboard ? line1_back.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            }) : line1.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            })
            }
            <Key keyVal={<TiBackspaceOutline />} bigKey actionKey={"backspace"}/>
        </div>
        <div className="line2">
            { toggleKeyboard ? line2_back.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            }) : line2.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            })
            }
        </div>
        <div className="line3">
            { toggleKeyboard ? line3_back.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            }) : line3.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            })
            }
        </div>
        <div className="line4">
            <Key keyVal={<BsShift />} bigKey actionKey={"shift"}/>
            { toggleKeyboard ? line4_back.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            }) : line4.map((key) => {
                return <Key key={key} keyVal={key} keyColor={keyState[key] != null ? keyState[key] : ""}/>;
            })
            }
            <Key keyVal={<BsArrowReturnLeft />} bigKey actionKey={"enter"}/>
        </div>
    </div>
  )
}

export default Keyboard;